<script setup lang="ts">
import checkout from './checkout.vue';
import defaultLayout from './default.vue';

const mobileView = ref(isMobileView());
const desktopView = ref(!isMobileView());

if (!process.client) {
	mobileView.value = true;
	desktopView.value = true;
}

onMounted(() => {
	mobileView.value = isMobileView();
	desktopView.value = !isMobileView();
});
</script>
<template>
	<div class="address-book">
		<defaultLayout v-if="desktopView" class="">
			<slot />
		</defaultLayout>
		<checkout v-if="mobileView">
			<slot />
		</checkout>
	</div>
</template>
